import { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/icons';

interface IProps {
  isDisabled: boolean;
  onClick: () => void;
  className?: string;
}

const Root = styled.button<{ isDisabled: boolean }>`
  width: 60px;
  height: 45px;
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  img {
    transition: transform 0.2s ease-in-out;
    transform: rotate(${({ isDisabled }) => isDisabled && '180deg'});
  }
`;

export const RefreshButton = (props: IProps) => {
  const { isDisabled, onClick, className } = props;

  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    onClick();

    setIsAnimating(true);
  };

  const onAnimatingEnd = () => setIsAnimating(false);

  return (
    <Root className={className} isDisabled={isAnimating} onClick={handleClick}>
      <Icon
        type="refresh"
        width="25px"
        height="25px"
        onTransitionEnd={onAnimatingEnd}
      />
    </Root>
  );
};
