import { Selector } from 'components/selector';
import { DEFAULT_INPUT_WITH, Theme } from 'theme';

import { INewGameSelectorProps } from './version-selector.typing';

export const NewGameSelector = (props: INewGameSelectorProps) => {
  const { label, placeholder, options, selected, errorMessage, onChange } =
    props;

  return (
    <div>
      <label>
        <Theme.ControlTitle>{label}</Theme.ControlTitle>

        <Selector
          options={options}
          value={selected}
          placeholder={placeholder}
          containerWidth={`${DEFAULT_INPUT_WITH}px`}
          onChange={onChange}
        />
      </label>

      {errorMessage && <Theme.ErrorMessage>{errorMessage}</Theme.ErrorMessage>}
    </div>
  );
};
