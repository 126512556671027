import { memo } from 'react';

import { COLORS } from 'theme';

import { InfoRectangle } from '../info-rectangle';
import { RefreshButton } from '../refresh-button';

import { useActiveGameEnergy } from './active-game-energy.state';

interface IProps {
  gameCode: string;
}

export const ActiveGameEnergy = memo(function ActiveGameEnergy(props: IProps) {
  const { total, players, isLoading, onClickUpdate } = useActiveGameEnergy(
    props.gameCode
  );

  return (
    <InfoRectangle
      title="Energy"
      icon="energy"
      iconWidth="21px"
      color={COLORS.lightRed}
      items={[
        { title: 'Total Energy left', value: total },
        { title: 'Players with Energy left', value: players },
      ]}
      HeaderComponent={
        <RefreshButton isDisabled={isLoading} onClick={onClickUpdate} />
      }
    />
  );
});
