import { realtimeDatabase } from 'services/firebase';

const refs = {
  players(gameCode: string) {
    return realtimeDatabase.ref('games').child(gameCode).child('players');
  },
};

export const activeGamePlayersDB = {
  async getPlayersCount(gameCode: string) {
    const ref = refs.players(gameCode);

    const count = (await ref.get()).numChildren();

    return count;
  },
};
