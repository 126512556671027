import { IGameIdentity, IRound, TGameStatus } from '@avid/common';

import { firebaseFunctions } from 'services/firebase';

const CALLABLE_FUNCTION = 'gameAdmin';

export const GAME_ADMIN_ACTIONS = {
  UPDATE_SALARIES: '@GAME_ADMIN/UPDATE_SALARIES',
  SWITCH_ROUND: '@GAME_ADMIN/SWITCH_ROUND',
  FIRE_PLAYERS: '@GAME_ADMIN/FIRE_PLAYERS',
  GET_SELF_PACED_PLAYERS: '@GAME_ADMIN/GET_SELF_PACED_PLAYERS',
} as const;

const callFunction = firebaseFunctions.httpsCallable(CALLABLE_FUNCTION);

interface IFirePlayersPayload {
  limits: Record<string, number>;
}

interface IGetSelfPacedPlayersData {
  roundPlayers: number[];
}

interface IGameAdminFunction {
  [GAME_ADMIN_ACTIONS.UPDATE_SALARIES]: {
    payload: { sectorSalaries: [string, number][] };
    data: undefined;
  };

  [GAME_ADMIN_ACTIONS.SWITCH_ROUND]: {
    payload: null;
    data: {
      gameCode: string;
      rounds: IRound[];
      status: TGameStatus;
    };
  };

  [GAME_ADMIN_ACTIONS.FIRE_PLAYERS]: {
    payload: IFirePlayersPayload;
    data: undefined;
  };

  [GAME_ADMIN_ACTIONS.GET_SELF_PACED_PLAYERS]: {
    payload: null;
    data: IGetSelfPacedPlayersData;
  };
}

export const callGameAdminFunction = async <
  T extends (typeof GAME_ADMIN_ACTIONS)[keyof typeof GAME_ADMIN_ACTIONS],
>(
  gameIdentity: IGameIdentity,
  type: T,
  payload: IGameAdminFunction[T]['payload']
): Promise<IGameAdminFunction[T]['data']> => {
  const result = await callFunction({ gameIdentity, type, payload });

  return result.data;
};
