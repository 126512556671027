import { ActiveGameWrapper } from '../active-game-wrapper';
import { ReflectionAnswers } from '../reflection-answers';
import { SelfPacedGamePanel } from '../self-paced-game-panel';

import { useSelfPacedGame } from './self-paced-game.state';

export const SelfPacedGame = () => {
  const { game, gameCode, isEndGameLoading, onEndGame } = useSelfPacedGame();

  return (
    <ActiveGameWrapper
      gameCode={gameCode}
      isEndGameDisabled={isEndGameLoading}
      FlowButton={<ReflectionAnswers />}
      onEndGame={onEndGame}
    >
      <SelfPacedGamePanel
        gameCode={gameCode}
        gameTitle={game.config.gameTitle}
        roundDuration={game.config.roundDuration}
        roundsNumber={+game.config.roundsNumber}
      />
    </ActiveGameWrapper>
  );
};
