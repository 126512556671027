import styled from 'styled-components';

import { Span } from 'components/typography';
import { COLORS } from 'theme';
import { memo } from 'react';

interface IProps {
  title: string;
  value: string;
}

const Root = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: auto auto;
  column-gap: 10px;
`;

const Value = styled(Span)`
  color: ${COLORS.lightRed};
`;

export const SummaryItem = memo(function SummaryItem(props: IProps) {
  const { title, value } = props;

  return (
    <Root>
      <Span>{title}</Span>
      <Value typography="QuicksandBold16">{value}</Value>
    </Root>
  );
});
