import { STRINGS } from 'constants/string';

import { EndGameModalStyles } from './end-game-modal.styles';

const { confirmButton, cancelButton } = STRINGS.END_GAME_MODAL;

export interface IEndGameModalProps {
  isRound: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
}

export const ConfirmModal = (props: IEndGameModalProps) => {
  const { isRound, isDisabled, onConfirm, onCancel } = props;

  return (
    <EndGameModalStyles.Root>
      <EndGameModalStyles.Title>
        Please confirm if you want to{' '}
        {isRound ? 'stop the round' : 'end this game'}?
      </EndGameModalStyles.Title>

      <EndGameModalStyles.ButtonsWrapper>
        <EndGameModalStyles.Button
          text={confirmButton}
          isDisabled={isDisabled}
          onClick={onConfirm}
        />
        <EndGameModalStyles.Button
          text={cancelButton}
          isDisabled={isDisabled}
          onClick={onCancel}
          isLightTheme
        />
      </EndGameModalStyles.ButtonsWrapper>
    </EndGameModalStyles.Root>
  );
};
