import styled from 'styled-components';

import { Grid } from 'components/UI';
import { Paragraph } from 'components/typography';
import { typographyCss } from 'theme';

import { ActiveGamePanelWrapper } from '../active-game-panel-wrapper';
import { ActiveGamePlayers } from '../active-game-players';
import { Summary } from '../summary';
import { SelfPacedGameRounds } from '../self-paced-game-rounds';

interface IProps {
  gameCode: string;
  gameTitle: string;
  roundDuration: number;
  roundsNumber: number;
}

const Styles = {
  Content: styled.div`
    padding-top: 20px;
  `,

  SelfTip: styled(Paragraph)`
    font-style: italic;
    ${typographyCss.QuicksandRegular16}
  `,
};

export const SelfPacedGamePanel = (props: IProps) => {
  const { gameCode, gameTitle, roundDuration, roundsNumber } = props;

  return (
    <ActiveGamePanelWrapper gameCode={gameCode} gameTitle={gameTitle}>
      <Styles.Content>
        <Grid rowGap="36px">
          <Grid justifyContent="space-between" columns={2}>
            <ActiveGamePlayers gameCode={gameCode} />

            <Styles.SelfTip>Self-paced game</Styles.SelfTip>
          </Grid>

          <Grid rowGap="20px">
            <Summary
              roundDuration={roundDuration}
              roundsNumber={roundsNumber}
            />

            <SelfPacedGameRounds />
          </Grid>
        </Grid>
      </Styles.Content>
    </ActiveGamePanelWrapper>
  );
};
