import { Modal } from 'components/modal';

import { ConfirmModal } from '../end-game-modal';

import { ShareGameModal, WorkLimitsModal } from './components';

import { IModalsProps } from './modals.typing';

export const Modals = (props: IModalsProps) => {
  const {
    gameCode,
    gameVersion,
    openModal,
    isEndGameLoading,
    onConfirmEndGame,
    onCloseModal,
  } = props;

  const onEndGame = async () => {
    if (isEndGameLoading) {
      return;
    }

    await onConfirmEndGame();

    onCloseModal();
  };

  const onCloseEndGameModal = () => {
    if (isEndGameLoading) {
      return;
    }

    onCloseModal();
  };

  return (
    <>
      <WorkLimitsModal
        isOpen={openModal === 'limits'}
        gameCode={gameCode}
        gameVersion={gameVersion}
        onClose={onCloseModal}
      />

      <Modal
        isOpen={openModal === 'end'}
        onCloseModal={onCloseEndGameModal}
        isCenterContent={true}
      >
        <ConfirmModal
          isRound={false}
          isDisabled={isEndGameLoading}
          onConfirm={onEndGame}
          onCancel={onCloseEndGameModal}
        />
      </Modal>

      <Modal
        isOpen={openModal === 'share'}
        onCloseModal={onCloseModal}
        isCenterContent={true}
      >
        <ShareGameModal gameCode={gameCode} onClose={onCloseModal} />
      </Modal>
    </>
  );
};
