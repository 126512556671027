import styled from 'styled-components';

import { Paragraph } from 'components/typography';
import { COLORS } from 'theme';

import { RefreshButton } from './refresh-button';

interface IProps {
  isDisabled: boolean;
  onClick: () => void;
}

const Styles = {
  Root: styled.div`
    display: grid;
    justify-self: start;
    grid-auto-flow: column;
    column-gap: 16px;
    align-items: center;
  `,

  RefreshButton: styled(RefreshButton)`
    background-color: ${COLORS.white};
  `,
};

export const SelfPacedGameRefreshRounds = (props: IProps) => {
  const { isDisabled, onClick } = props;

  return (
    <Styles.Root>
      <Styles.RefreshButton isDisabled={isDisabled} onClick={onClick} />

      <Paragraph typography="QuicksandMedium16">
        Refresh round players
      </Paragraph>
    </Styles.Root>
  );
};
