import { useCallback, useEffect, useState } from 'react';

import { gamesFirestore } from 'services/games';

interface IState {
  isLoading: boolean;
  total?: number;
  players?: number;
}

const INIT_STATE: IState = {
  isLoading: true,
};

export const useActiveGameEnergy = (gameCode: string) => {
  const [state, setState] = useState(INIT_STATE);

  const updateEnergy = useCallback(async () => {
    const players = await gamesFirestore.getGamePlayers(gameCode);

    let total = 0;
    let playersCount = 0;

    for (const player of players) {
      if (!player.main?.energy) {
        continue;
      }

      total += player.main.energy;
      playersCount += 1;
    }

    setState({ isLoading: false, total, players: playersCount });
  }, [gameCode]);

  const onClickUpdate = async () => {
    if (state.isLoading) {
      return;
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    await updateEnergy();
  };

  useEffect(() => {
    updateEnergy();
  }, [updateEnergy]);

  return { ...state, onClickUpdate };
};
