import { useCallback, useEffect, useState } from 'react';

import { activeGamePlayersDB } from './active-game-players.db';
import { activeGamePlayersFirestore } from './active-game-players.firestore';

interface IState {
  total?: number;
  createdCharacter?: number;
}

const INTERVAL = 30000;

const getPlayerCounts = async (gameCode: string) => {
  const [total, createdCharacter] = await Promise.all([
    activeGamePlayersDB.getPlayersCount(gameCode),
    activeGamePlayersFirestore.countPlayersCreatedCharacter(gameCode),
  ]);

  return { total, createdCharacter };
};

export const useActiveGamePlayers = (gameCode: string) => {
  const [state, setState] = useState<IState>({});

  const updatePlayersCount = useCallback(async () => {
    const { createdCharacter, total } = await getPlayerCounts(gameCode);

    setState({ total, createdCharacter });
  }, [gameCode]);

  useEffect(() => {
    updatePlayersCount();

    const interval = setInterval(updatePlayersCount, INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [updatePlayersCount]);

  return { ...state };
};
