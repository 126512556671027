import { useMemo } from 'react';
import styled from 'styled-components';

import { formatDuration } from 'services/utils';

import { SummaryItem } from './summary-item';

interface IProps {
  roundsNumber: number;
  roundDuration: number;
  timeElapsed?: number;
}

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: start;
  column-gap: 50px;
`;

export const Summary = (props: IProps) => {
  const { roundsNumber, roundDuration, timeElapsed } = props;

  const timeElapsedFormat = timeElapsed
    ? formatDuration(timeElapsed)
    : undefined;

  const RenderTimeElapsed = timeElapsedFormat ? (
    <SummaryItem title="Total time elapsed: " value={timeElapsedFormat} />
  ) : null;

  const roundDurationFormat = useMemo(
    () => formatDuration(roundDuration),
    [roundDuration]
  );

  return (
    <Root>
      <SummaryItem
        title="Total number of rounds: "
        value={roundsNumber.toString()}
      />

      <SummaryItem title="Duration per Round: " value={roundDurationFormat} />

      {RenderTimeElapsed}
    </Root>
  );
};
