import { useCallback, useEffect, useState } from 'react';

import { MainThunk } from 'screens/main';
import { PLATFORM_AXIOS_PATHS, postPlatformAuth } from 'services/api';
import { getNowMoment } from 'services/utils';
import { useThunkDispatch } from 'services/hooks';

import { useActiveGameContext } from '../../active-game.context';

export const useSelfPacedGame = () => {
  const { game } = useActiveGameContext();

  const [isEndGameLoading, setIsEndGameLoading] = useState(false);

  const gameCode = game.config.gameCode;

  const dispatch = useThunkDispatch();

  const onEndGame = useCallback(async () => {
    if (isEndGameLoading) {
      return;
    }

    setIsEndGameLoading(true);

    try {
      if (Boolean(game.config.mods?.gameReport)) {
        postPlatformAuth(PLATFORM_AXIOS_PATHS.SEND_GAME_REPORTS, {
          gameCode,
        });
      }

      await dispatch(
        MainThunk.updateGameAsync({
          gameCode,
          status: 'finished',
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsEndGameLoading(false);
    }
  }, [dispatch, game.config.mods?.gameReport, gameCode, isEndGameLoading]);

  useEffect(() => {
    const checkAndFinishGame = () => {
      if (getNowMoment().isAfter(game.config.endAt)) {
        onEndGame();
      }
    };

    checkAndFinishGame();

    const interval = setInterval(() => {
      checkAndFinishGame();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [game.config.endAt, onEndGame]);

  return { game, gameCode, isEndGameLoading, onEndGame };
};
