export const GAME_FLOW_SELECTOR_OPTIONS = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Self-paced',
    value: 'self',
  },
];
