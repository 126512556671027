import { Grid } from 'components/UI';
import { Loader } from 'components/loader';
import { Paragraph } from 'components/typography';

import { SelfPacedGameRefreshRounds } from '../self-paced-game-refresh-rounds';
import { SelfPacedGameRoundsProgress } from '../self-paced-game-rounds-progress';

import { useSelfPacedRounds } from './self-paced-game-rounds.state';

export const SelfPacedGameRounds = () => {
  const { isLoading, roundPlayers, onRefresh } = useSelfPacedRounds();

  let Element: React.ReactNode = null;

  if (isLoading) {
    Element = <Loader />;
  } else if (roundPlayers) {
    Element = <SelfPacedGameRoundsProgress roundPlayers={roundPlayers} />;
  } else {
    Element = <Paragraph>Error</Paragraph>;
  }

  return (
    <Grid rowGap="32px">
      <SelfPacedGameRefreshRounds isDisabled={isLoading} onClick={onRefresh} />

      {Element}
    </Grid>
  );
};
