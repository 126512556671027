import { useCallback, useMemo } from 'react';

import { IActiveGameButton } from 'components/active-game-buttons';
import { history } from 'services/history';
import { ROUTES } from 'constants/routes';
import { TActiveGameModal } from 'typings/games';

import { useUpdateState } from './react';
import { useReduxSelector } from './redux';
import { useGameByGameCode } from './admin-games';

interface IState<TModals extends string = never> {
  isSettings: boolean;
  modal: TActiveGameModal | TModals;
}

const getInitState = <TModals extends string = never>(): IState<TModals> => ({
  isSettings: false,
  modal: 'none',
});

export const useActiveGame = <TModals extends string = never>(
  gameCode: string
) => {
  const isGamesFetching = useReduxSelector(
    (redux) => redux.main.isGamesFetching
  );

  const { game } = useGameByGameCode(gameCode);

  const { state, updateState } = useUpdateState(getInitState<TModals>());

  const onCloseModal = useCallback(
    () => updateState({ modal: 'none' }),
    [updateState]
  );

  const openModalCb = useCallback(
    (modal: TActiveGameModal | TModals) => () => {
      updateState({ modal });
    },
    [updateState]
  );

  const onWatchPlayersList = useCallback(
    () => history.push(ROUTES.PLAYERS, gameCode),
    [gameCode]
  );

  const onWatchStatistic = useCallback(
    () => history.push(ROUTES.STATISTIC, gameCode),
    [gameCode]
  );

  const onWatchPlayerHistory = useCallback(
    () => history.push(ROUTES.PLAYERS_HISTORY, gameCode),
    [gameCode]
  );

  const onChangeSettingsView = useCallback(() => {
    updateState((prev) => ({ ...prev, isSettings: !prev.isSettings }));
  }, [updateState]);

  const buttons: IActiveGameButton[] = useMemo(
    () => [
      {
        icon: 'playersPurple',
        title: 'Player List',
        onClick: onWatchPlayersList,
      },
      {
        icon: 'pieGraphBig',
        title: 'Game Statistics',
        onClick: onWatchStatistic,
      },
      {
        icon: 'suitcase',
        title: 'Change Job Limits',
        onClick: openModalCb('limits'),
      },
      {
        icon: 'playersHistoryPurple',
        title: 'Players History',
        onClick: onWatchPlayerHistory,
      },
      {
        icon: state.isSettings ? 'gameSettingsWhite' : 'gameSettingsPurple',
        title: 'Game Settings',
        isActive: state.isSettings,
        onClick: onChangeSettingsView,
      },
      { icon: 'share', title: 'Share', onClick: openModalCb('share') },
    ],
    [
      state.isSettings,
      onChangeSettingsView,
      openModalCb,
      onWatchPlayerHistory,
      onWatchPlayersList,
      onWatchStatistic,
    ]
  );

  return {
    ...state,
    game,
    buttons,
    isGamesFetching,
    onCloseModal,
    openModalCb,
  };
};
