import styled from 'styled-components';

import { Button } from 'components/button';
import { Paragraph } from 'components/typography';
import { ActiveGameButtons } from 'components/active-game-buttons';
import { TabLayout } from 'components/tab-layout';
import { useActiveGame } from 'services/hooks';

import { Modals } from './modals';
import { GameSettings } from './game-settings';

interface IProps {
  gameCode: string;
  isEndGameDisabled: boolean;
  children: React.ReactNode;
  onEndGame: () => Promise<void>;
  FlowButton?: React.ReactNode;
}

const Styles = {
  ActiveGamePanelWrapper: styled.div`
    margin-top: 16px;
  `,

  Tab: styled(TabLayout)`
    justify-content: normal;
  `,

  TabWrapper: styled.div`
    padding-top: 64px;
  `,

  Button: styled(Button)`
    margin-top: 0;

    &:disabled {
      opacity: 0.7;
      cursor: auto;
    }
  `,

  BottomButtonsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  `,
};

export const ActiveGameWrapper = (props: IProps) => {
  const { gameCode, children, isEndGameDisabled, FlowButton, onEndGame } =
    props;

  const { game, buttons, isSettings, modal, onCloseModal, openModalCb } =
    useActiveGame(gameCode);

  const statusLabel = game?.config.isSelfPaced
    ? 'has started'
    : game?.rounds?.length
      ? 'is in progress'
      : 'has not been started';

  const RenderTab = isSettings ? (
    <GameSettings gameCode={gameCode} />
  ) : (
    <>
      <Paragraph typography="RalewayBold24">Your game {statusLabel}</Paragraph>

      <Styles.ActiveGamePanelWrapper>{children}</Styles.ActiveGamePanelWrapper>

      <Styles.BottomButtonsContainer>
        <Styles.Button
          text="End current game"
          isDisabled={isEndGameDisabled}
          onClick={openModalCb('end')}
          isLightTheme
        />

        {FlowButton}
      </Styles.BottomButtonsContainer>
    </>
  );

  return (
    <>
      <Modals
        gameCode={gameCode}
        gameVersion={game?.config.version}
        openModal={modal}
        isEndGameLoading={isEndGameDisabled}
        onCloseModal={onCloseModal}
        onConfirmEndGame={onEndGame}
      />

      <Styles.Tab>
        <ActiveGameButtons buttons={buttons} />

        <Styles.TabWrapper>{RenderTab}</Styles.TabWrapper>
      </Styles.Tab>
    </>
  );
};
