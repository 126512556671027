import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';

import { MenuWrapper } from 'components/menu-wrapper';
import { Loader } from 'components/loader';
import { ExportData } from 'screens/export-data';
import { Home } from 'screens/home';
import { Auth } from 'screens/auth';
import { SignIn } from 'screens/sign-in';
import { PlayersList } from 'screens/players-list';
import { ActiveGameProxy } from 'screens/active-game';
import { Statistics } from 'screens/statistics';
import { PlayersHistoryScreen } from 'screens/players-history';
import { NewGame } from '../new-game/new-game';
import { PrivateRoute } from './private-route';

import { ROUTES } from 'constants/routes';
import { PasswordLogin } from 'screens/password-login';
import { GamesUpload } from 'screens/games-upload';
import { ConstantsUploaderLazy } from 'screens/constants-uploader';

const ShareGameLoader = lazy(() => import('screens/share-game-loader'));

export const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Switch>
          <Route path={ROUTES.AUTH} component={Auth} exact />
          <Route path={ROUTES.SIGN_IN} component={SignIn} exact />
          <Route path={ROUTES.LOGIN} component={PasswordLogin} exact />
          <PrivateRoute
            isAdminRoute={true}
            path={ROUTES.EXPORT_DATA}
            component={ExportData}
          />
          <PrivateRoute
            isAdminRoute={true}
            path={ROUTES.UPLOAD_GAMES}
            component={GamesUpload}
          />
          <PrivateRoute
            isAdminRoute={true}
            path={ROUTES.UPLOAD_CONSTANTS}
            component={ConstantsUploaderLazy}
          />

          <Route path={`${ROUTES.SHARE}/:page`} component={ShareGameLoader} />

          <MenuWrapper>
            <Switch>
              <PrivateRoute exact path={ROUTES.START} component={Home} />
              <PrivateRoute path={ROUTES.NEW_GAME} component={NewGame} />
              <PrivateRoute
                path={ROUTES.ACTIVE_GAME + '/:gameCode'}
                component={ActiveGameProxy}
              />
              <PrivateRoute
                exact
                path={ROUTES.STATISTIC}
                component={Statistics}
              />
              <PrivateRoute
                path={ROUTES.STATISTIC + '/:gameCode'}
                component={Statistics}
              />
              <PrivateRoute path={ROUTES.PLAYERS} component={PlayersList} />
              <PrivateRoute
                path={ROUTES.PLAYERS_HISTORY}
                component={PlayersHistoryScreen}
              />

              <Redirect to={ROUTES.START} />
            </Switch>
          </MenuWrapper>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};
