import { Span } from 'components/typography';
import { Grid } from 'components/UI';
import styled from 'styled-components';

import { COLORS } from 'theme';

interface IProps {
  roundPlayers: number[];
}

const Styles = {
  Root: styled.div`
    display: grid;
    justify-content: start;
    row-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  `,

  RoundTrack: styled.div`
    display: grid;
    justify-content: start;
    grid-template-columns: auto 1fr;
    align-items: center;
  `,

  Dot: styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${COLORS.yellow};
  `,

  Line: styled.div`
    height: 3px;
    background-color: ${COLORS.yellow};
  `,
};

export const SelfPacedGameRoundsProgress = (props: IProps) => {
  const { roundPlayers } = props;

  const RenderRounds = roundPlayers.map((players, index) => (
    <Grid key={index} rowGap="12px">
      <Span typography="QuicksandBold16">Round {index + 1}</Span>

      <Styles.RoundTrack>
        <Styles.Dot />
        <Styles.Line />
      </Styles.RoundTrack>

      <Span typography="QuicksandMedium16">{players} players</Span>
    </Grid>
  ));

  return <Styles.Root>{RenderRounds}</Styles.Root>;
};
