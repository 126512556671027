import { useCallback, useEffect, useRef, useState } from 'react';
import { entriesObject } from '@avid/common';

import {
  usePositionLimitsEditor,
  useSectorJobsMapLoader,
  useUpdateState,
} from 'services/hooks';
import { TSectorSettings, TWorkLimitParam } from 'typings/sectors-settings';
import { gamesAPI } from 'services/api';
import { keyofObject, valuesOfObject } from 'services/object';

import { useActiveGameContext } from '../../../../active-game.context';

import {
  firePlayers,
  updateSalaries,
  unchangeSectorSettings,
  getSectorSettings,
  getSelectionStateKey,
} from './work-limits.utils';
import { workLimitsDB } from './work-limits.db';

interface IApplies {
  limit: boolean;
  salary: boolean;
}

interface IState {
  isLoading: boolean;
  isSaving: boolean;
  selectedLimits: string[];
  selectedSalaries: string[];
  isApplied: IApplies;
  sectorSettings?: TSectorSettings;
}

const INIT_STATE: IState = {
  isLoading: true,
  isSaving: false,
  isApplied: {
    limit: false,
    salary: false,
  },
  selectedLimits: [],
  selectedSalaries: [],
};

export const useWorkLimits = (gameCode: string) => {
  const { game } = useActiveGameContext();

  const { state, updateState } = useUpdateState(INIT_STATE);

  const [isShowNotification, setIsShowNotification] = useState(false);

  const initSectorSettingsRef = useRef<TSectorSettings | null>(null);
  const notificationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const positionLimitsEditorHook = usePositionLimitsEditor();

  const { sectorJobsMap } = useSectorJobsMapLoader({
    version: game?.config.version,
  });

  const isAllLimitsSelected = Boolean(
    state.sectorSettings &&
      keyofObject(state.sectorSettings)?.every((sector) =>
        state.selectedLimits.includes(sector)
      )
  );

  const isAllSalariesSelected = Boolean(
    state.sectorSettings &&
      keyofObject(state.sectorSettings)?.every((sector) =>
        state.selectedSalaries.includes(sector)
      )
  );

  const getInitItem = useCallback(
    (sector: string) => initSectorSettingsRef.current?.[sector],
    []
  );

  const changeItemCallback = useCallback(
    (param: TWorkLimitParam) => (sector: string, value: number) => {
      if (value < 0) {
        return;
      }

      updateState((prev) => {
        if (
          prev.sectorSettings?.[sector][param].value === value ||
          !prev.sectorSettings
        ) {
          return prev;
        }

        const itemsCopy = { ...prev.sectorSettings };
        const objectKey = getSelectionStateKey(param);

        const updateItem = (sectorUpdate: string) => {
          itemsCopy[sectorUpdate] = {
            ...itemsCopy[sectorUpdate],
            [param]: {
              value,
              isChanged: getInitItem(sectorUpdate)?.[param].value !== value,
            },
          };
        };

        const newState: IState = {
          ...prev,
          isApplied: { ...prev.isApplied, [param]: false },
        };

        if (prev[objectKey].includes(sector)) {
          for (const selected of prev[objectKey]) {
            updateItem(selected);
          }
        } else {
          updateItem(sector);
        }

        newState.sectorSettings = itemsCopy;

        return newState;
      });
    },
    [getInitItem, updateState]
  );

  const onApplyItems = useCallback(
    (param: TWorkLimitParam) => {
      updateState((prev) => ({
        ...prev,
        isApplied: { ...prev.isApplied, [param]: true },
      }));
    },
    [updateState]
  );

  const showNotification = () => {
    setIsShowNotification(true);

    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }

    notificationTimeoutRef.current = setTimeout(
      () => setIsShowNotification(false),
      5000
    );
  };

  const hideNotification = () => {
    setIsShowNotification(false);

    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }
  };

  const onUndraggableMouseDown = (ev: React.MouseEvent) => {
    ev.stopPropagation();
  };

  const selectItem = useCallback(
    (param: TWorkLimitParam, sector: string) => {
      const objectKey = getSelectionStateKey(param);

      updateState((prev) => {
        const isSelected = prev[objectKey].includes(sector);

        let newSelected;

        if (isSelected) {
          newSelected = prev[objectKey].filter((item) => item !== sector);
        } else {
          newSelected = [...prev[objectKey], sector];
        }

        return { ...prev, [objectKey]: newSelected };
      });
    },
    [updateState]
  );

  const selectAllItems = useCallback(
    (param: TWorkLimitParam) => {
      const objectKey = getSelectionStateKey(param);

      updateState((prev) => {
        const unselected =
          prev.sectorSettings &&
          keyofObject(prev.sectorSettings).filter(
            (sector) => !prev[objectKey].includes(sector)
          );

        if (!unselected?.length) {
          return prev;
        }

        return {
          ...prev,
          [objectKey]: [...prev[objectKey], ...unselected],
        };
      });
    },
    [updateState]
  );

  const unselectAllItems = useCallback(
    (param: TWorkLimitParam) => {
      const objectKey = getSelectionStateKey(param);

      updateState({ [objectKey]: [] });
    },
    [updateState]
  );

  const toggleAllItems = useCallback(
    (param: TWorkLimitParam, isAllItemsSelected: boolean) => {
      if (isAllItemsSelected) {
        unselectAllItems(param);
      } else {
        selectAllItems(param);
      }
    },
    [selectAllItems, unselectAllItems]
  );

  const getChangedEntires = () =>
    state.sectorSettings &&
    entriesObject(state.sectorSettings)?.filter(
      ([, item]) => item.limit.isChanged || item.salary.isChanged
    );

  const isChangedLimitItems = Boolean(
    state.sectorSettings &&
      valuesOfObject(state.sectorSettings).filter(
        (item) => item.limit.isChanged
      ).length
  );

  const isChangedSalaryItems = Boolean(
    state.sectorSettings &&
      valuesOfObject(state.sectorSettings).filter(
        (item) => item.salary.isChanged
      ).length
  );

  const isDisabledLimitsApply = !isChangedLimitItems || state.isApplied.limit;
  const isDisabledSalariesApply =
    !isChangedSalaryItems || state.isApplied.salary;

  const isCanSave =
    (!isChangedLimitItems || state.isApplied.limit) &&
    (!isChangedSalaryItems || state.isApplied.salary);

  const save = async () => {
    if (state.isSaving) {
      return;
    }

    hideNotification();

    const changedEntries = getChangedEntires();

    const isPositionLimitsChanged = positionLimitsEditorHook.checkChanged();

    const tasks: (() => Promise<any>)[] = [];

    if (changedEntries?.length) {
      updateSalaries(gameCode, changedEntries);

      const task = async () => {
        await Promise.all(
          changedEntries?.map(([sector, item]) =>
            workLimitsDB.updateSectorParams({
              gameCode,
              sector,
              limit: item.limit.value,
              salary: +(item.salary.value * 0.01).toFixed(1),
            })
          )
        );

        await firePlayers(gameCode, changedEntries);

        const sectorSettings = unchangeSectorSettings(
          state.sectorSettings || {}
        );

        initSectorSettingsRef.current = structuredClone(sectorSettings);

        updateState({ sectorSettings });
      };

      tasks.push(task);
    }

    if (isPositionLimitsChanged) {
      const task = async () => {
        await gamesAPI.setPositionLimits(
          gameCode,
          positionLimitsEditorHook.items
        );

        positionLimitsEditorHook.updateInitItems();
      };

      tasks.push(task);
    }

    if (!tasks.length) {
      return;
    }

    updateState({ isSaving: true });

    await Promise.all(tasks.map((task) => task()));

    updateState({ isSaving: false });

    showNotification();
  };

  useEffect(() => {
    return () => {
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const request = async () => {
      const sectors = await workLimitsDB.getSectors(gameCode);

      const sectorSettings = getSectorSettings(sectors);

      updateState({ isLoading: false, sectorSettings });

      initSectorSettingsRef.current = structuredClone(sectorSettings);
    };

    request();
  }, [gameCode, updateState]);

  useEffect(() => {
    const request = async () => {
      const positionLimits = await gamesAPI.getPositionLimits(gameCode);

      if (!positionLimits) {
        return;
      }

      positionLimitsEditorHook.setInitItems(positionLimits.items);
    };

    request();
  }, [gameCode, positionLimitsEditorHook.setInitItems]);

  return {
    ...state,
    isCanSave,
    sectorJobsMap,
    positionLimitsEditorHook,
    isShowNotification,
    isAllLimitsSelected,
    isAllSalariesSelected,
    isDisabledLimitsApply,
    isDisabledSalariesApply,
    onApplyItems,
    onUndraggableMouseDown,
    selectItem,
    toggleAllItems,
    changeItemCallback,
    save,
  };
};
