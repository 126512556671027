import { useCallback, useEffect } from 'react';
import { TGameStatus, IRound } from '@avid/common';

import {
  useReduxSelector,
  useThunkDispatch,
  useUpdateState,
} from 'services/hooks';
import { getGameIdentity, getPassedGameTime } from 'services/utils';
import {
  GAME_ADMIN_ACTIONS,
  PLATFORM_AXIOS_PATHS,
  callGameAdminFunction,
  postPlatformAuth,
} from 'services/api';
import { STRINGS } from 'constants/string';

import { MainActions, MainThunk } from '../../../main';

import { useActiveGameContext } from '../../active-game.context';

import { makeRoundSnapshot, stopLastRoundInArray } from './standard-game.utils';

interface IState {
  timeElapsed: number;
  isLoading: boolean;
  isShowStopRoundModal: boolean;
}

const { ACTIVE_GAME } = STRINGS;

const INITIAL_STATE: IState = {
  timeElapsed: 0,
  isLoading: false,
  isShowStopRoundModal: false,
};

export const useStandardGame = (gameCode: string) => {
  const { game } = useActiveGameContext();

  const isGamesFetching = useReduxSelector(
    (redux) => redux.main.isGamesFetching
  );

  const { config, rounds = [] } = game;
  const { roundDuration, roundsNumber } = config;

  const { state, updateState } = useUpdateState(INITIAL_STATE);

  const thunkDispatch = useThunkDispatch();

  const beforeLastRoundTimeElapsed = getPassedGameTime(rounds);

  const currentRound = rounds.length;

  const updateGames = useCallback(
    async (newStatus: TGameStatus, newRounds: IRound[]) => {
      await thunkDispatch(
        MainThunk.updateGameAsync({
          gameCode,
          rounds: newRounds,
          status: newStatus,
          timeElapsed: state.timeElapsed,
        })
      );
    },
    [thunkDispatch, gameCode, state.timeElapsed]
  );

  const updateElapsedTime = useCallback(
    (interval: number) =>
      updateState({ timeElapsed: beforeLastRoundTimeElapsed + interval }),
    [beforeLastRoundTimeElapsed, updateState]
  );

  const onEndGame = useCallback(async () => {
    if (state.isLoading) {
      return;
    }

    updateState({ isLoading: true });

    try {
      if (Boolean(game.config.mods?.gameReport)) {
        postPlatformAuth(PLATFORM_AXIOS_PATHS.SEND_GAME_REPORTS, {
          gameCode,
        });
      }

      await updateGames('finished', rounds);
    } catch (error) {
      console.error(error);
    } finally {
      updateState({ isLoading: false });
    }
  }, [
    game.config.mods?.gameReport,
    gameCode,
    rounds,
    state.isLoading,
    updateGames,
    updateState,
  ]);

  const startRoundAsync = useCallback(async () => {
    if (state.isLoading) {
      return;
    }

    updateState({ isLoading: true });

    try {
      const { rounds: updatedRounds, status } = await callGameAdminFunction(
        getGameIdentity(gameCode),
        GAME_ADMIN_ACTIONS.SWITCH_ROUND,
        null
      );

      thunkDispatch(
        MainActions.updateGame(gameCode, { rounds: updatedRounds, status })
      );
    } catch (error) {
      console.error(error);
    } finally {
      updateState({ isLoading: false });
    }
  }, [thunkDispatch, gameCode, state.isLoading, updateState]);

  const endRoundAsync = useCallback(() => {
    if (!rounds || !rounds.length) {
      return;
    }

    const updatedRounds = stopLastRoundInArray(rounds, roundDuration);
    const newGameStatus =
      rounds.length >= +roundsNumber ? 'finished' : 'paused';

    makeRoundSnapshot(gameCode, currentRound);
    updateGames(newGameStatus, updatedRounds);
  }, [
    gameCode,
    rounds,
    roundDuration,
    roundsNumber,
    currentRound,
    updateGames,
  ]);

  const openStopRoundModal = useCallback(() => {
    updateState({ isShowStopRoundModal: true });
  }, [updateState]);

  const closeStopRoundModal = useCallback(() => {
    updateState({ isShowStopRoundModal: false });
  }, [updateState]);

  const onStopRound = useCallback(() => {
    endRoundAsync();
    updateState({ isShowStopRoundModal: false });
  }, [endRoundAsync, updateState]);

  const buttonProps =
    game.status === 'in-progress'
      ? {
          text: ACTIVE_GAME.stopRound,
          onClick: openStopRoundModal,
        }
      : { text: ACTIVE_GAME.startRound, onClick: startRoundAsync };

  useEffect(() => {
    updateElapsedTime(0);
  }, [updateElapsedTime]);

  return {
    ...state,

    game,
    isDisabled: state.isLoading || isGamesFetching,
    gameCode,
    buttonProps,

    startRoundAsync,
    endRoundAsync,
    onStopRound,
    updateElapsedTime,
    closeStopRoundModal,
    onEndGame,
  };
};
