import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IReduxState, TActions } from 'typings/redux';

export const useReduxSelector: TypedUseSelectorHook<IReduxState> = useSelector;

export const useThunkDispatch = () => {
  const dispatch = useDispatch();

  return dispatch as ThunkDispatch<IReduxState, {}, TActions>;
};
