import { useCallback, useRef } from 'react';
import { CSVLink } from 'react-csv';
import {
  entriesObject,
  IPlayerStored,
  TPlayerReflectionAnswers,
  TReflectionQuestions,
} from '@avid/common';

import { clientFirebase } from 'services/firebase';
import { useUpdateState } from 'services/hooks';

import { useActiveGameContext } from '../../active-game.context';

interface IMapDataToRowsParams {
  players: IPlayerStored[];
  answers: [string, TPlayerReflectionAnswers][];
  questions?: TReflectionQuestions;
}

interface IAnswerRow {
  playerName: string;
  playerEmail: string;
  characterName: string;
  round: number;
  question: string;
  answer: string;
  updatedAt: string;
}

interface IState {
  isLoading: boolean;
  rows?: IAnswerRow[];
}

const INIT_STATE: IState = {
  isLoading: false,
};

const mapDataToRows = (params: IMapDataToRowsParams): IAnswerRow[] => {
  const { players, questions, answers } = params;

  if (!players.length || !questions || !answers.length) {
    return [];
  }

  const mapped = answers.map(([email, playerAnswers]) => {
    const player = players.find((pla) => pla.authInfo.email === email);

    if (!player) {
      return null;
    }

    return entriesObject(playerAnswers).map(([questionId, answerData]) => ({
      playerEmail: email,
      characterName: player.createCharacter?.name,
      playerName: player.authInfo.name,
      question: questions[questionId].question,
      answer: answerData.answer,
      round: answerData.round,
      updatedAt: answerData.updatedAt,
    })) as IAnswerRow[];
  });

  return (mapped.filter(Boolean) as IAnswerRow[][]).flat();
};

export const useReflectionAnswers = () => {
  const { game } = useActiveGameContext();

  const gameCode = game.config.gameCode;

  const { state, updateState } = useUpdateState(INIT_STATE);

  const linkRef = useRef(CSVLink) as any;

  const onClick = useCallback(async () => {
    if (state.isLoading) {
      return;
    }

    try {
      const [players, questions, answers] = await Promise.all([
        clientFirebase.firestore.games.getPlayers(gameCode),
        clientFirebase.firestore.constants.getReflectionQuestions(),
        clientFirebase.firestore.games.getGameReflectionAnswers(gameCode),
      ]);

      const rows = mapDataToRows({ players, answers, questions });

      updateState({ isLoading: false, rows });

      setTimeout(() => {
        linkRef.current.link.click();
      }, 0);
    } catch (error) {
      console.error(error);
      updateState({ isLoading: false });
    }
  }, [gameCode, state.isLoading, updateState]);

  return { ...state, linkRef, onClick };
};
