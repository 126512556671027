import { RouteComponentProps, Redirect } from 'react-router-dom';
import deepEqual from 'deep-equal';

import { useReduxSelector } from 'services/hooks';
import { ROUTES } from 'constants/routes';
import { IActiveGameMatchParams } from 'typings/games';

import { SelfPacedGame, StandardGame } from './components';

import { ActiveGameContext } from './active-game.context';

export const ActiveGameProxy = (
  props: RouteComponentProps<IActiveGameMatchParams>
) => {
  const { match } = props;

  const gameCode = match.params.gameCode;

  const game = useReduxSelector((redux) =>
    redux.main.games.find((g) => g.config.gameCode === gameCode, deepEqual)
  );

  if (!game) {
    return <Redirect to={ROUTES.START} />;
  }

  if (game.status === 'finished') {
    return <Redirect to={`${ROUTES.STATISTIC}/${gameCode}`} />;
  }

  const RenderElement = game.config.isSelfPaced ? (
    <SelfPacedGame />
  ) : (
    <StandardGame gameCode={gameCode} />
  );

  return (
    <ActiveGameContext.Provider value={{ game }}>
      {RenderElement}
    </ActiveGameContext.Provider>
  );
};
