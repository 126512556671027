import { Theme } from 'theme';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
}

export const EndAtField = (props: IProps) => {
  const { value, errorMessage, onChange } = props;

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;
    onChange(newValue);
  };

  return (
    <Theme.TextInput
      title="Expiry Date and Time (DD/MM/YYYY HH:MM)"
      name="endAt"
      mask="99/99/9999 99:99"
      placeholder="DD/MM/YYYY HH:MM"
      value={value}
      errorMessage={errorMessage}
      onChange={handleChange}
    />
  );
};
