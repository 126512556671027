export const STRINGS = {
  AUTH: {
    logIn: {
      logoTitle: 'ADVENTURES',
      title: 'Career Design Life Game Facilitator Panel',
      placeholder: {
        email: 'Email',
        password: 'Password',
      },
      error: '*You have entered wrong email or password',
      nextButtonStr: 'Log In',
    },
  },
  HOME: {
    noInProgress: 'Hi! You have no games in progress currently',
    createGameButton: 'Create Game',
    yourGames: 'Your games',
    noCreated: 'Hi! You have no games created',
    pleaseClick: 'Please click "Create Game" to get started.',
  },

  SIDEBAR: {
    buttons: {
      home: 'Home',
      gameStats: 'Game Stats',
      players: 'Players',
      playersHistory: 'Players History',
    },
  },
  CARDS: {
    startGame: 'Start game',
    viewGame: 'View Game Stats',
  },
  ACTIVE_GAME: {
    gameStatistics: 'Game Statistics',
    playerList: 'Player List',
    changeJobLimits: 'Change Job Limits',
    playersHistory: 'Players History',
    startRound: 'Start round',
    stopRound: 'Stop round',
    gameName: 'Game Name',
    gameCode: 'Game Code',
    totalPlayers: 'Total players in game:',
    totalRounds: 'Total number of rounds:',
    round: 'Current round:',
    totalElapsed: 'Total time elapsed:',
    roundInfo: 'Current Round Info',
    totalEnergy: 'Total Energy left',
    playersEnergy: 'Players with Energy left',

    notStartedStatus: 'not started',
    zeroDuration: 'Duration 0',
    roundStr: 'round',
    statusStr: 'Status',
    durationStr: 'Duration',
    min: 'min',
    refresh: 'Refresh',
    games: 'games',
    players: 'players',
  },
  STATISTIC: {
    showAll: 'Show All',
    round: 'round',
    selectGame: 'Select Game',
    searchBy: 'Search by game code/name/industry/level/etc...',
    applyButton: 'Apply',
    excelButton: 'Export Excel',
    currentStatusBoxes: 'Current Status',
    employee: 'Employee',
    nonEmployee: 'Non-Employee',
    employeesDistribution: 'Distribution of Employees in Sectors',
    total: 'Total',
    industriesGraph: 'Industries',
    averageSalaryTitle: 'Average Salary by Sector',
    sector: 'Sector',
    averageSalary: 'Average Salary',
    leaderBoards: 'Leaderboards',
    earners: 'Top Earners',
    position: 'Position',
    name: 'Name',
    popularSkills: 'Top 10 Most Popular Skills',
    skill: 'Skill',
    peopleNumber: 'Number of People',
    highestPositions: 'Highest Positions',
    jpbLevel: 'Job Level',
    educated: 'Most Highly Educated',
    degree: 'Degree',
    happiest: 'Happiest People',
    satisfactionLevel: 'Satisfaction Level',
    possessions: 'Possessions',
    emptyInfoMessage: "There's no one here yet.",
    loading: 'Loading...',
    noStatistics: 'There’s no any statistics data yet',
  },
  PLAYERS_LIST: {
    title: 'Player List',
    table: {
      name: 'Name',
      characterName: 'Character Name',
      email: 'Email',
    },
  },

  END_GAME_MODAL: {
    title: 'Please confirm if you want to end this game?',
    confirmButton: 'Confirm',
    cancelButton: 'Cancel',
  },
  EXPORT_DATA: {
    buttonTitle: 'Export all data',
  },
} as const;
