import styled from 'styled-components';

import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { IActiveGameMatchParams } from 'typings/games';

import { ActiveGameWrapper } from '../active-game-wrapper';
import { StandardGamePanel } from '../standard-game-panel';
import { ConfirmModal } from '../end-game-modal';

import { useStandardGame } from './standard-game.state';

export const Styles = {
  Button: styled(Button)`
    margin-top: 0;

    &:disabled {
      opacity: 0.7;
      cursor: auto;
    }
  `,
};

export const StandardGame = (props: IActiveGameMatchParams) => {
  const { gameCode } = props;

  const {
    isDisabled,
    timeElapsed,
    isShowStopRoundModal,

    buttonProps,
    game,

    endRoundAsync,
    closeStopRoundModal,
    updateElapsedTime,
    onEndGame,
    onStopRound,
  } = useStandardGame(gameCode);

  const RenderModal = isShowStopRoundModal ? (
    <Modal isOpen onCloseModal={closeStopRoundModal} isCenterContent={true}>
      <ConfirmModal
        isRound
        isDisabled={isDisabled}
        onConfirm={onStopRound}
        onCancel={closeStopRoundModal}
      />
    </Modal>
  ) : null;

  return (
    <ActiveGameWrapper
      gameCode={gameCode}
      isEndGameDisabled={isDisabled}
      FlowButton={<Styles.Button isDisabled={isDisabled} {...buttonProps} />}
      onEndGame={onEndGame}
    >
      <StandardGamePanel
        rounds={game.rounds}
        gameCode={gameCode}
        gameTitle={game.config.gameTitle}
        roundDuration={game.config.roundDuration}
        roundsNumber={+game.config.roundsNumber}
        timeElapsed={timeElapsed}
        endRound={endRoundAsync}
        addTimeElapsed={updateElapsedTime}
      />

      {RenderModal}
    </ActiveGameWrapper>
  );
};
