import moment from 'moment';
import { IRound } from '@avid/common';

import { getGameIdentity, getNowMoment } from 'services/utils';
import { callableFunctions } from 'services/api';

export const stopLastRoundInArray = (
  rounds: IRound[],
  roundDuration: number
) => {
  const roundsCopy = [...rounds];
  const lastRound = { ...roundsCopy[roundsCopy.length - 1] };

  const nowMoment = getNowMoment();

  const duration =
    nowMoment.unix() - moment(new Date(lastRound.startTime)).unix();
  const isTimeExceed = duration > roundDuration;

  lastRound.endTime = isTimeExceed
    ? lastRound.startTime + roundDuration
    : nowMoment.format();
  lastRound.duration = isTimeExceed ? roundDuration : duration;
  lastRound.status = 'ended';

  roundsCopy[roundsCopy.length - 1] = lastRound;

  return roundsCopy;
};

export const makeRoundSnapshot = async (gameCode: string, round: number) => {
  try {
    await callableFunctions.roundSnapshot({
      gameIdentity: getGameIdentity(gameCode),
      round,
    });
  } catch (error) {
    console.error(error);
  }
};
