import styled from 'styled-components';

export const StandardGamePanelStyles = {
  MiddleWrapper: styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 140px;
  `,

  SummaryWrapper: styled.div`
    margin-top: 40px;
  `,

  GameProgressWrapper: styled.div`
    margin-top: 32px;
  `,

  RectanglesWrapper: styled.div`
    display: grid;
    width: fit-content;
    row-gap: 30px;
  `,
};
