import styled from 'styled-components';

import { COLORS } from 'theme';

import { Paragraph } from 'components/typography';

interface IProps {
  gameCode: string;
  gameTitle: string;
  children: React.ReactNode;
}

const Styles = {
  Root: styled.div`
    border-radius: 20px;
    border: 1px solid ${COLORS.light_gray};
    background-color: ${COLORS.blue_gray};
    padding: 32px;
  `,

  Header: styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  `,
};

export const ActiveGamePanelWrapper = (props: IProps) => {
  const { gameCode, gameTitle, children } = props;

  return (
    <Styles.Root>
      <Styles.Header>
        <Paragraph typography="QuicksandBold18">
          Game Name “{gameTitle}”
        </Paragraph>

        <Paragraph typography="QuicksandBold18">Game Code {gameCode}</Paragraph>
      </Styles.Header>

      {children}
    </Styles.Root>
  );
};
