import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useUpdateState } from 'services/hooks';
import { IActiveGameMatchParams } from 'typings/games';
import { callGameAdminFunction, GAME_ADMIN_ACTIONS } from 'services/api';
import { getGameIdentity } from 'services/utils';

interface IState {
  isLoading: boolean;
  roundPlayers?: number[];
}

const INIT_STATE: IState = {
  isLoading: true,
};

export const useSelfPacedRounds = () => {
  const { gameCode } = useParams<IActiveGameMatchParams>();

  const { state, updateState } = useUpdateState(INIT_STATE);

  const loadPlayers = useCallback(async () => {
    try {
      const { roundPlayers } = await callGameAdminFunction(
        getGameIdentity(gameCode),
        GAME_ADMIN_ACTIONS.GET_SELF_PACED_PLAYERS,
        null
      );

      updateState({
        isLoading: false,
        roundPlayers,
      });
    } catch (error) {
      updateState({ isLoading: false });
      console.error(error);
    }
  }, [gameCode, updateState]);

  const onRefresh = useCallback(() => {
    updateState({ isLoading: true });

    loadPlayers();
  }, [updateState, loadPlayers]);

  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);

  return { ...state, onRefresh };
};
