import { createContext, useContext } from 'react';

import { IGame } from '@avid/common';

interface IActiveGameContext {
  game: IGame;
}

export const ActiveGameContext = createContext<IActiveGameContext | null>(null);

export const useActiveGameContext = () => {
  const context = useContext(ActiveGameContext);

  if (!context) {
    throw new Error('No ActiveGameContext');
  }

  return { ...context };
};
